import React from 'react';

import classNames from 'classnames';
import {
  DayClickEventHandler,
  Matcher,
  DayPicker,
  DayPickerBase,
} from 'react-day-picker';
import { RxCalendar } from 'react-icons/rx';

import { ThemeName } from 'theme/types';
import { getReadableDate } from 'utils/common';

import { Typography } from '../Typography/Typography';
import 'react-day-picker/dist/style.css';
import './DatePicker.css';

type DayPickerWithInputProps =
  | {
      withInput?: true;
      label: string;
      placeholder: string;
      helperText?: string;
      disableHelperText?: boolean;
      toggleDayPicker: (v: boolean) => void;
      open: boolean;
      error?: boolean;
    }
  | {
      withInput?: false;
      label?: never;
      placeholder?: never;
      helperText?: never;
      disableHelperText?: never;
      toggleDayPicker?: never;
      open?: never;
      error?: never;
    };

type DayPicker = {
  selectedDate?: Date;
  onDateSelect: DayClickEventHandler;
  disableDays?: Matcher | Matcher[];
  theme?: ThemeName;
  classes?: {
    dayPicker?: string;
  };
} & DayPickerWithInputProps;

const dayPickerTheme: Record<ThemeName, string> = {
  [ThemeName.Light]:
    'border border-[#cacaca] bg-white p-2 text-background-contrastText [&_.rdp-day:hover]:!bg-primary-dark [&_.rdp-day:hover]:!text-white',
  [ThemeName.Dark]:
    'border border-[#393939] bg-background-light p-2 text-background-contrastText [&_.rdp-day:hover]:!bg-primary-main [&_.rdp-day:hover]:!text-background-contrastText',
};
const dayPickerStyles: Record<ThemeName, DayPickerBase['styles']> = {
  [ThemeName.Light]: {
    cell: {
      fontSize: '0.9rem',
    },
    head_cell: {
      color: '#2a7769',
    },
    dropdown: {
      border: 'none',
      fontSize: '0.8rem',
    },
  },
  [ThemeName.Dark]: {
    cell: {
      fontSize: '0.9rem',
    },
    head_cell: {
      color: '#359583',
    },
    dropdown: {
      backgroundColor: '#262626',
      color: '#cccccc',
      border: 'none',
      padding: '0.3rem',
      fontSize: '0.8rem',
    },
  },
};

function ReactDayPicker({
  classes,
  theme = ThemeName.Light,
  selectedDate,
  disableDays,
  onDateSelect,
  open,
}: Pick<
  DayPicker,
  | 'classes'
  | 'withInput'
  | 'theme'
  | 'onDateSelect'
  | 'selectedDate'
  | 'disableDays'
  | 'open'
>) {
  return (
    <DayPicker
      className={classNames(
        'absolute right-0 top-full z-[999999999999] !m-0 w-max translate-y-1 rounded-md shadow-[15px_15px_15px_0px_rgba(0,0,0,0.30)]',
        {
          ' hidden ': !open,
        },
        classes?.dayPicker,
        dayPickerTheme[theme]
      )}
      mode="single"
      modifiersClassNames={{
        disabled: '!text-background-contrastText',
        selected: '!text-white !bg-primary-dark !text-md',
        today: 'text-primary-main font-bold text-xl',
      }}
      styles={dayPickerStyles[theme]}
      selected={selectedDate}
      disabled={disableDays}
      fromYear={new Date().getFullYear() - 100}
      toYear={new Date().getFullYear() + 100}
      onDayClick={onDateSelect}
      captionLayout="dropdown-buttons"
    />
  );
}

export function DayPickerWithInput(props: DayPicker) {
  const {
    label,
    withInput = true,
    toggleDayPicker,
    open,
    error,
    selectedDate,
    placeholder,
    disableHelperText,
    helperText,
  } = props;
  const renderDayPicker = withInput ? (
    <div className="relative ">
      {label && (
        <label htmlFor="date-input">
          <Typography className="mb-1 opacity-80" variant="h5">
            {label}
          </Typography>
        </label>
      )}
      <button
        name="date-input"
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            toggleDayPicker?.(!open);
          }
        }}
        type="button"
        className={classNames(
          'relative  flex w-full items-center rounded-md border border-background-light bg-background-light px-3 py-3 ',
          {
            ' border border-error-light ': error,
          }
        )}>
        {!selectedDate ? (
          <span className="pointer-events-none mr-5 max-w-[15rem] overflow-hidden text-ellipsis whitespace-nowrap text-base text-zinc-400 3xl:text-xl">
            {placeholder}
          </span>
        ) : (
          <span className="pointer-events-none text-base text-background-contrastText 3xl:text-xl">
            {getReadableDate(new Date(selectedDate).toISOString())}
          </span>
        )}
        <RxCalendar className="pointer-events-none ml-auto text-background-contrastText" />
        <ReactDayPicker {...props} />
      </button>
      {!disableHelperText && (
        <p
          className={classNames(
            'm-1 h-[0.8rem] text-left text-sm text-background-contrastText ',
            {
              'h-[1rem] !text-error-main': !!error,
            }
          )}>
          {helperText}
        </p>
      )}
    </div>
  ) : (
    <ReactDayPicker {...props} open classes={{ dayPicker: 'relative' }} />
  );

  return renderDayPicker;
}
