import React, { useEffect } from 'react';

import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

export function Logout() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const activeRole = localStorage.getItem('active_role');
    localStorage.removeItem('login_redirect');
    if (searchParams.get('role') === activeRole) {
      localStorage.removeItem('active_role');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate to="/login" state={{ from: location }} replace />;
}
